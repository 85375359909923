
var adBlockEnabled = false;
var testAd = document.createElement('div');
testAd.innerHTML = '&nbsp;';
testAd.className = 'adsbox';
document.body.appendChild(testAd);
var global_addblock = false;

var sendAdblockValue= function(type){
    $.ajax({
        type: 'GET',
        url: '/adblock/' + type + '/not_show'
    });
}
window.setTimeout(function() {
    //console.log(app_session_adblock_var)
    if(typeof app_session_adblock_var !== 'undefined' && app_session_adblock_var == "show") {
        if (testAd.offsetHeight === 0) {
            adBlockEnabled = true;
        }
        testAd.remove();
        //console.log('AdBlock Enabled? ', adBlockEnabled);
        if (adBlockEnabled && !global_addblock) {
            swal({
                    title: "Warning",
                    text: "MediaForce might not work as expected when an ad blocker is enabled in a web browser. Turn off the ad blocker or add this web page's URL as an exception so you can create ads without any problems. After you turn off the ad blocker, you'll need to refresh your screen.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Don't show me this message again",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    cancelButtonText: "Close",
                },
                function (isConfirm) {
                    if(isConfirm){
                        global_addblock = true;
                        app_session_adblock_var = "not_show";
                        sendAdblockValue("app_session_adblock_var");
                    }
                    //callDonotTrack()
                });
            console.log("addblock");
        } else {
            console.log(adBlockEnabled);
            console.log(global_addblock);
            //callDonotTrack()
        }
    }
    else{
        //callDonotTrack()
    }
}, 1000);

/*var donottrack = false;
var callDonotTrack = function() {
    window.setTimeout(function () {
        //console.log('AdBlock Enabled? ', adBlockEnabled);
        if (app_session_donottrack_var == "show") {
            if (navigator.doNotTrack == 1 && !donottrack) {
            swal({
                    title: "Warning",
                    text: "MediaForce might not work as expected when your browser's \"Do Not Track\" feature is enabled. Please disable this feature and refresh your screen.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Don't show me this message again",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    cancelButtonText: "Close",
                },
                function (isConfirm) {
                    if(isConfirm) {
                        donottrack = true;
                        app_session_donottrack_var = "not_show";
                        sendAdblockValue("app_session_donottrack_var")
                    }
                });
                console.log("donottrack");
            } else {
                console.log(navigator.doNotTrack);
                console.log(donottrack);
            }
        }
    }, 1000);
}*/


/**
 * Main application jQuery logic.
 */

/**
 * If an error is encountered via ajax, return the response in an modal for debug.
 *
 * @param responseText
 */
function debug(responseText) {
    var modal = $('#js-debug-modal');
    if(responseText.indexOf("Unauthenticated.") === -1 && !login_redirected_app) {
        if (modal.length) {
            if (app_env_value == "production") {
                modal.find('.modal-title').html("Something went wrong!");
                modal.find('.modal-body').html('The application has encountered an unknown error. Please try again. <br /><br />If the error persists, please contact the support team: <a href="javascript:void(0);" data-toggle="modal" data-target="#globalHelpModal" title="Help &amp; Support"><i class="fa fa-life-ring fa-fw"></i>Help &amp; Support</a>');
                modal.modal('show');
            } else {
                modal.find('.modal-body').html(responseText);
                modal.modal('show');
            }
        }
    }else{
        window.location.reload();
        login_redirected_app = true;
    }
    console.log(app_env_value);
    console.log('responseText: ', responseText);
}

/**
 * Show main container loader.
 */
function show_loader(event = null) {
    console.log(event)
    if((event !== null && event.ctrlKey) || (typeof wsGoingOut !== "undefined" && !wsGoingOut)){}
    else
        $('.mainloader').fadeIn();
}

/**
 * Hide main container loader.
 */
function hide_loader() {
    $('.mainloader').fadeOut();
}

function show_overlay_loader(container) {
    $(container).prepend('<div class="mainloader" style="margin-top: 0;"><div class="cssload-speeding-wheel"></div></div>');
}

function hide_overlay_loader(container) {
    $(container).find('.mainloader').remove();
}

/**
 * Init switchery
 */
var init_switchery = function () {
    $('.js-switch').each(function () {
        if (!$(this).hasClass('switch-init')) {
            new Switchery($(this)[0], $(this).data());
            $(this).addClass('switch-init');
        }
    });
};

$(document).ready(function () {
    init_switchery();
    $(".select2-selection.select2-selection--multiple:not(:has(i.fa.fa-search.search-icon-input))").prepend("<i class='fa fa-search search-icon-input'></i>");
    $('.open-market-li a').on('click', function(e){
        show_loader(e);
    })
});

$(document).on('click', '.mm-fullsubopen', function(event) {
    console.log("INIT");
    var href = $(event.target).attr('href');
    if(href.indexOf('adv-content') !== -1) {
        var arr = href.split("-");
        var advertiser_id = arr[arr.length - 1];
        $("#adv-content-" + advertiser_id).html('<div style="margin-top: 50px" class="text-center"><div class="cssload-speeding-wheel"></div><br><h4>Loading Advertiser ...</h4></div>');
        $.ajax({
            type: "GET",
            url: '/advertiser/'+ advertiser_id +'/loading/menu',
            dataType: 'json',
            success: function (data) {
                $("#adv-content-" + advertiser_id).html(data['data']);
                for(var ind in data['panels']){
                    if($("#camp-content-" + ind).length === 0){
                        $(".mm-panels").append(data['panels'][ind]);
                        console.log("new: " + ind);
                    }else{
                        console.log($("#camp-content-" + ind).length);
                    }
                }
                console.log("DONE");
            },
            error: function (xhr) {
                console.log("ERROR:");
                ajax_error();
            }
        });
    }else if(href.indexOf('camp-content') !== -1){
        console.log("CAMP");
        var arr = href.split("-");
        var camp_id = arr[arr.length - 1];
        arr = $(event.target).attr("data-adv").split("-");
        var adv_id = arr[arr.length - 1];
        var locked = $(event.target).attr("data-locked");
        $(".campaign_" + camp_id).html('<div style="margin-top: 50px" class="text-center"><div class="cssload-speeding-wheel"></div><br><h4>Loading Campaign ...</h4></div>');
        $("#camp-content-" + camp_id).removeClass("mm-hidden");
        $.ajax({
            type: "GET",
            url: '/advertiser/'+ adv_id +'/campaign/'+ camp_id +'/loading/menu/' + locked,
            dataType: 'json',
            success: function (data) {
                $(".campaign_" + camp_id).html(data['data']);
                //$("#camp-content-" + camp_id).addClass("mm-iconpanel-" + camp_id);
                console.log("DONE CAMP");
            },
            error: function (xhr) {
                debug(xhr.responseText);
                ajax_error();
            }
        });
    }
});
$(document).on('keyup', '.menu_custom_search',function () {
    console.log($(".menu_custom_search").val())
    console.log($(this).parent().parent(),$(this).parent().parent().children("ul.mm-listview").children("li"))
    $(this).parent().parent().children("ul.mm-listview").children("li").each(function (item, value) {
        console.log(item,value)
        if(typeof $(value).attr("title") !== "undefined"){
            if($(value).attr("title").toLowerCase().includes($(".menu_custom_search").val().toLowerCase()))
                $(value).css("display","block")
            else
                $(value).css("display","none")
        }
    })
})
$(document).on('click', '.open-market-li-blocked',function () {
    var text = $(this).attr('title');
    swal({
            title: "Warning!",
            text: text,
            type: "warning",
            showCancelButton: false,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "Ok, I got it!",
            cancelButtonText: "No",
            closeOnConfirm: true,
            closeOnCancel: true
        },
        function (isConfirm) {
            if(isConfirm){}
        });
})



/**
 * Panel Heading Click-to-collapse
 */

$(document).on('click', '.panel-heading span.clickable', function (e) {
    var $this = $(this);
    if (!$this.hasClass('panel-collapsed')) {
        $this.parents('.panel').find('.panel-body').slideUp();
        $this.addClass('panel-collapsed');
        $this.find('i').removeClass('glyphicon-minus').addClass('glyphicon-plus');
    } else {
        $this.parents('.panel').find('.panel-body').slideDown('slow');
        $this.removeClass('panel-collapsed');
        $this.find('i').removeClass('glyphicon-plus').addClass('glyphicon-minus');
    }
});
$(document).on('click', '.panel div.clickable', function (e) {
    var $this = $(this);
    if (!$this.hasClass('panel-collapsed')) {
        $this.parents('.panel').find('.panel-body').slideUp();
        $this.addClass('panel-collapsed');
        $this.find('i').removeClass('glyphicon-minus').addClass('glyphicon-plus');
    } else {
        $this.parents('.panel').find('.panel-body').slideDown('slow');
        $this.removeClass('panel-collapsed');
        $this.find('i').removeClass('glyphicon-plus').addClass('glyphicon-minus');
    }
});
$(document).ready(function () {
    $('.panel:not(".panel-open") .panel-heading span.clickable').click();
    $('.panel:not(".panel-open") div.clickable').click();
});

/**
 * jQuery Ellipsis
 */

$('.ellipsis-single-line').ellipsis({
    lines: 1,
    responsive: true
});

/**
 * Echo

 var echo = new Echo({
    broadcaster: 'pusher',
    key: $('meta[name="pusher-key"]').attr('content'),
    cluster: $('meta[name="pusher-cluster"]').attr('content'),
    encrypted: true
});
 **/

/**
 * STTabs
 */

(function () {
    [].slice.call(document.querySelectorAll('.sttabs')).forEach(function (el) {
        if (!$(el).hasClass('sttabs-static')) {
            new CBPFWTabs(el);
        }
    });
})();

/**
 * Top Nav Widgets
 */

$('#top-nav li.calendar a').on('click', function (event) {
    $('#top-nav li.calculator').removeClass('open');
    $(this).parent().toggleClass('open');
});

$('body').on('click', function (e) {
    if (!$('#top-nav li.calendar').is(e.target)
        && $('#top-nav li.calendar').has(e.target).length === 0
        && $('.open').has(e.target).length === 0
    ) {

        $('#top-nav li.calendar').removeClass('open');
    }
});
// $('.select2').on('select2:opening', function(e) {
//     $(this).data('select2').$dropdown.find(':input.select2-search__field').attr('placeholder', 'Search ...')
// });
$('select').on('select2:opening', function(e) {
    $(this).data('select2').$dropdown.find(':input.select2-search__field').attr('placeholder', 'Search ...')
});

$('#top-nav li.calculator a').on('click', function (event) {
    $('#top-nav li.calendar').removeClass('open');
    $(this).parent().toggleClass('open');
});

$('body').on('click', function (e) {
    if (!$('#top-nav li.calculator').is(e.target)
        && $('#top-nav li.calculator').has(e.target).length === 0
        && $('.open').has(e.target).length === 0
    ) {

        $('#top-nav li.calculator').removeClass('open');
    }
});

/**
 * Bootstrap Tooltips
 */
var init_tooltips = function () {
    $('[data-toggle="tooltip"]').each(function () {
        if (!$(this).hasClass('tooltip-init') && !$(this).hasClass('menu-tooltip')) {
            $(this).tooltip({container: 'body'});
            $(this).addClass('tooltip-init');
        }
    });
};


$(document).ready(function () {
    init_tooltips();
    function checkSessionExpiredTime(){
        setTimeout(function () {
            $.ajax({
                type: 'GET',
                url: '/dashboard',
                success: function (data) {
                    checkSessionExpiredTime()
                },
                error: function (xhr) {
                    hide_loader();
                    debug(xhr.responseText);
                    console.log("CHECK SESSION: ", xhr.status, xhr.responseText)
                    if(xhr.status === 401 && xhr.responseText.indexOf("Unauthenticated") !== -1){
                        global_notification("error","Your session has expired, you will be redirected to the login page.",5000)
                        setTimeout(function () {
                            window.location.href = window.location.protocol + "//" + window.location.hostname + "/login"
                        }, 6000)
                    }
                }
            });
        }, 60000)
    }
    checkSessionExpiredTime()
});

/**
 * Global Ajax Error Handling
 */

var ajax_error = function () {
   // global_notification('error', 'There was an issue processing that request. Please contact support if the issue persists.');
};

/**
 * Moment
 **/

moment().locale('en');

/**
 * Global notifications
 **/

var global_notification_icons = {
    alert: '<i class="fa fa-bullhorn fa-lg fa-pull-left fa-fw"></i>',
    success: '<i class="fa fa-check fa-lg fa-pull-left fa-fw"></i>',
    error: '<i class="fa fa-exclamation-triangle fa-lg fa-pull-left fa-fw"></i>',
    warning: '<i class="fa fa-bell fa-lg fa-pull-left fa-fw"></i>',
    info: '<i class="fa fa-info-circle fa-lg fa-pull-left fa-fw"></i>'
};

var ws_global_notification_queue = {};

function global_notification(type, text, timeout, id, buttons) {
    if (typeof timeout === 'undefined') {
        timeout = 4000;
    }
    if (typeof buttons === 'undefined') {
        buttons = [];
    }

    // If we're repeating the same notification id, reset timeout instead of duplicating.
    if (typeof id === 'undefined') {
        new Noty({
            type: type,
            layout: 'topRight',
            theme: 'bootstrap-v3',
            text: global_notification_icons[type] + text,
            timeout: timeout,
            progressBar: true,
            closeWith: ['click', 'button'],
            animation: {
                open: 'animated fadeInRight',
                close: 'animated fadeOutRight'
            },
            visibilityControl: false,
            buttons: buttons
        }).show();
    } else {
        if (!ws_global_notification_queue.hasOwnProperty(id)) {
            new_noty = new Noty({
                type: type,
                layout: 'topRight',
                theme: 'bootstrap-v3',
                text: global_notification_icons[type] + text,
                timeout: timeout,
                progressBar: true,
                closeWith: ['click', 'button'],
                animation: {
                    open: 'animated fadeInRight',
                    close: 'animated fadeOutRight'
                },
                visibilityControl: false,
                id: id,
                buttons: buttons
            }).on('onClose', function () {
                delete ws_global_notification_queue[this.id];
            });
            ws_global_notification_queue[id] = new_noty;
            new_noty.show();
        } else {
            ws_global_notification_queue[id].setTimeout(timeout);
        }
    }

}


/*
 * Client Side Error Logging
 */

window.onerror = function (e) {
    try {
        $.ajax({
            type: 'GET',
            url: '/log_error',
            data: 'error=' + e
        });
    } catch (err) {
    }
    return false;
};


/**
 * Searchable multiSelect definition
 */
var searchableMultiSelectDef = {

    selectableHeader: "<i class='fa fa-search search-icon-input'></i><input type='text' class='search-input full-width form-control' autocomplete='off' placeholder='Search...'>",
    selectionHeader: "<div style='height: 38px;'><label>Selected</label></div>",
    afterInit: function(ms){
        var that = this,
            $selectableSearch = that.$selectableUl.prev(),
            selectableSearchString = '#'+that.$container.attr('id')+' .ms-elem-selectable:not(.ms-selected)';

        that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
            .on('keydown', function(e){
                if (e.which === 40){
                    that.$selectableUl.focus();
                    return false;
                }
            });
    },
    afterSelect: function(){
        this.qs1.cache();
    },
    afterDeselect: function(){
        this.qs1.cache();
    }
};


var marketMultiSelect = function(id){
    $(id).multiSelect({
        selectableHeader: "<i class='fa fa-search search-icon-input'></i><input type='text' class='search-input full-width form-control' autocomplete='off' placeholder='Search...'>",
        selectionHeader: "<div style='height: 38px;'><label>Selected</label></div>",
        afterInit: function(ms){
            var that = this,
                $selectableSearch = that.$selectableUl.prev(),
                selectableSearchString = '#'+that.$container.attr('id')+' .ms-elem-selectable:not(.ms-selected)';

            that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
                .on('keydown', function(e){
                    if (e.which === 40){
                        that.$selectableUl.focus();
                        return false;
                    }
                });
        },
        afterSelect: function(values){
            this.qs1.cache();
        },
        afterDeselect: function(values){
            this.qs1.cache();
        }
    });
};
